<template>
  <div class="body">
    <div class="body-content">
      <div class="row-name">{{ title }}</div>
      <div class="line"></div>
      <div class="practicing">
        <div v-if="subjectInfo.type === 1" class="practicing-type">单选题</div>
        <div v-if="subjectInfo.type === 2" class="practicing-type">多选题</div>
        <div v-else-if="subjectInfo.type === 3" class="practicing-type">
          简答题
        </div>
        <div class="practicing-content">
          <div class="practicing-title">
            <span>{{ subjectInfo.number }}、</span><span v-html="subjectInfo.title"></span>
          </div>
          <div></div>
          <!-- 单选题、多选题 -->
          <div v-if="subjectInfo.type == 1 || subjectInfo.type == 2">
            <div v-if="pattern == '1'">
              <!-- 单选 -->
              <div v-if="subjectInfo.type === 1">
                <div class="practicing-option" v-for="(item, index) in subjectInfo.topicOptions" :key="index"
                  @click="chooseAnswer(index, subjectInfo.id, item.opt)" :class="subjectInfo.analysisList.answer == item.opt
                    ? 'select'
                    : 'no-select'
                    " :style="'color:' +
    (subjectInfo.analysisList.yourAnswer == item.opt ||
      subjectInfo.analysisList.answer == item.opt
      ? subjectInfo.analysisList.yourAnswer ==
        subjectInfo.analysisList.answer
        ? '#4394ff'
        : '#FF4343'
      : '#000')
    ">
                  <span v-if="!(item.opt === subjectInfo.analysisList.answer)" style="margin-right: 10px">{{ item.opt
                  }}</span>
                  <img v-else-if="item.opt === subjectInfo.analysisList.answer" src="../../assets/true.png" width="24px"
                    height="24px" />
                  <span v-html="item.value"></span>
                </div>
              </div>

              <!-- 多选 -->
              <div v-if="subjectInfo.type === 2">
                <div class="practicing-option" v-for="(item, index) in subjectInfo.topicOptions" :key="index"
                  @click="onSelect(index, subjectInfo.id, item.opt)" :class="item.isClick ? 'select' : 'no-select'"
                  :style="'color:' + (item.isClick ? '#4394ff' : '')">
                  <span v-if="!item.isClick" style="margin-right: 10px">{{
                    item.opt
                  }}</span>
                  <img v-else-if="item.isClick" src="../../assets/true.png" width="24px" height="24px" />
                  <span v-html="item.value"></span>
                </div>
                <div class="confirm-btn" @click="chooseAnswerChoice(subjectInfo.id, multiple, true)"
                  v-show="(this.pattern == '1' || this.pattern == '3') && !subjectInfo.answerBool">
                  确认答案
                </div>
              </div>
            </div>

            <!-- 考试模式 -->
            <div v-else>
              <div class="practicing-option" v-for="(item, index) in subjectInfo.topicOptions" :key="index"
                @click="chooseAnswer(index, subjectInfo.id, item.opt)" :class="item.isClick ? 'select' : 'no-select'"
                :style="'color:' + (item.isClick ? '#4394ff' : '')">
                <span v-if="!item.isClick" style="margin-right: 10px">{{
                  item.opt
                }}</span>
                <img v-else-if="item.isClick" src="../../assets/true.png" width="24px" height="24px" />
                <span v-html="item.value"></span>
              </div>
            </div>
          </div>
          <div v-else style="margin-top: 10px">
            <el-input type="textarea" :rows="5" placeholder="请输入内容" v-model="subjectInfo.briefAnswer" @input="input"
              :disabled="subjectInfo.answerBool">
            </el-input>
            <div class="confirm-btn" @click="
              chooseAnswerChoice(
                subjectInfo.id,
                subjectInfo.briefAnswer,
                true
              )
              " v-if="(this.pattern == '1' || this.pattern == '3') && !subjectInfo.answerBool">
              确认答案
            </div>
          </div>
          <div class="practicing-answer" v-if="showAnalysis && subjectInfo.type !== 3">
            <div class="practicing-answer-div">
              <div class="practicing-answer-text">选择答案</div>
              <div class="practicing-answer-option" :style="{
                color: analysisInfo.correct == 0 ? '#FF4343' : '#4394ff',
              }">
                {{ analysisInfo.yourAnswer }}
              </div>
            </div>
            <div class="practicing-answer-div">
              <div class="practicing-answer-text">正确答案</div>
              <div class="practicing-answer-option" v-html="analysisInfo.answer"></div>
            </div>
          </div>
          <div v-if="showAnalysis && viewResolution" class="practicing-analysis">
            <span class="analytical-title">本题解析</span>
            <p v-if="analysisInfo.analysis !== ''" v-html="analysisInfo.analysis"></p>
            <p v-else>无</p>

            <span v-if="subjectInfo.type === 3 && subjectInfo.answerBool" class="analytical-title">本题答案</span>
            <p v-if="subjectInfo.type === 3" v-html="analysisInfo.answer"></p>
          </div>
        </div>
        <div class="practicing-time">
          <div class="practicing-time-clock">
            <img src="../../assets/clock.png" width="36px" height="36px" />
            <span class="practicing-time-clock-text">{{ useTime }}</span>
            <el-button type="text" class="practicing-time-clock-text-fold" v-if="show === true"
              @click="show = !show">收起</el-button>
            <el-button type="text" class="practicing-time-clock-text-fold" v-else @click="show = !show">展开</el-button>
          </div>
          <div class="practicing-time-option" v-show="show">
            <div class="option-number">共 {{ subject.topics ? subject.topics.length : 0 }} 道题</div>
            <div class="option-line"></div>
            <ul class="option-options">
              <div v-for="(item, index) in questionNumber" :key="index" :style="{
                backgroundColor: item.id == questionNumberId ? '#438EFF' : '',
                color: item.id == questionNumberId ? 'white' : 'black',
              }" :class="['option-option', item.isClick ? 'isSelect' : 'notSelect',]"
                @click="goToNewQuestion(item, index)">
                {{ item.num + 1 }}
              </div>
            </ul>
            <div class="option-bottom">
              <el-pagination small layout="prev, pager, next" :page-size="count" :current-page.sync="pagesu"
                :total="subject.topics != undefined ? subject.topics.length : 0" class="option-bottom-page"
                @current-change="currentChange">
              </el-pagination>
              <div class="option-bottom-submit" @click="nextQuestion('提交')">
                提交试卷
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="body-button">
        <div class="body-button-content">
          <el-button type="primary" class="body-button-button" @click="lastQuestion" :disabled="topDisabled">上一题
          </el-button>
          <div class="body-button-center">
            <div v-if="pattern == '1'" class="body-button-center">
              <img src="../../assets/stars-2.png" width="24px" height="24px" class="body-button-center-img"
                @click="collection()" v-show="!subjectInfo.collection" />
              <div class="body-button-center-text" @click="collection()" v-show="!subjectInfo.collection">
                收藏
              </div>
              <img src="../../assets/stars-1.png" width="24px" height="24px" class="body-button-center-img"
                v-show="subjectInfo.collection" @click="collection()" />
              <div class="body-button-center-text" v-show="subjectInfo.collection" @click="collection()">
                已收藏
              </div>
            </div>
          </div>
          <div class="judge" v-if="pattern === '1'">
            <img style="width: 22px; height: 22px" src="../../assets/right.png" alt="" />
            <span class="rightTopic">{{ rightTopic }}</span>
            <img style="width: 22px; height: 22px" src="../../assets/wrong.png" alt="" />
            <span class="mistakeTopic">{{ mistakeTopic }}</span>
          </div>
          <el-button type="primary" class="body-button-button" @click="nextQuestion(nextQuestionValue)">{{
            nextQuestionValue
          }}</el-button>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible" width="680px" :before-close="handleClose" :close-on-click-modal="false">
      <div class="circle" style="height: 200px">
        <img src="../../assets/circular1.png" width="54" height="54" class="circular1" />
        <img src="../../assets/circular2.png" width="113" height="113" class="circular2" />
        <div class="center-img">
          <div>
            <div class="center-img-content">{{ correctRate }}%</div>
            <div class="center-img-text">正确率</div>
          </div>
        </div>
        <img src="../../assets/circular3.png" width="19" height="19" class="circular3" />
        <img src="../../assets/circular4.png" width="65" height="65" class="circular4" />
        <img src="../../assets/circular5.png" width="35" height="35" class="circular5" />
      </div>
      <div class="dialog-center">
        <div class="circle-title">{{ result2.appTestVo.name }}</div>
        <div class="circle-statistics">
          <div class="circle-statistics-left">
            <div v-if="minute1" class="circle-statistics-title">
              {{ result2.useTime }}分钟
            </div>
            <div v-else class="circle-statistics-title">
              {{ result2.useTime }}秒
            </div>
            <div class="circle-statistics-text">答题时间</div>
          </div>
          <div class="circle-statistics-center"></div>
          <div class="circle-statistics-right">
            <div class="circle-statistics-title">{{ correctQuantity }}</div>
            <div class="circle-statistics-text">正确题目</div>
          </div>
        </div>
        <div class="divider"></div>
      </div>
      <div class="dialog-options">
        <ul class="option-options dialog-option">
          <div v-for="(item, index) in questionNumber2" :key="index" :class="[
            'option-option',
            item.correct == 0 ? 'error' : 'font-white',
          ]">
            {{ item.num + 1 }}
          </div>
        </ul>
      </div>
      <el-pagination small layout="prev, pager, next" :page-size="36" :total="result2.appTestVo.topics != undefined
        ? result2.appTestVo.topics.length
        : 0
        " class="option-result-page" @current-change="currentChange2">
      </el-pagination>
      <div class="view-all-analysis" @click="viewAllAnalysis()">
        查看全部解析
      </div>
    </el-dialog>
    <el-dialog :visible.sync="exitVisible" width="530px">
      <div class="el-dialogVisible">
        <div class="exit-title">{{ exitTitle }}</div>
        <div class="exit-content">
          您还有<span class="color-red">{{ unfinished }}</span>道题未完成
        </div>
        <img style="width: 352px; height: 144px" src="../../assets/tuichu.png" alt="" />
        <div class="exit-list">
          <ul>
            <li class="color-red font-14">{{ unfinished }}</li>
            <li class="font-14">未答题数</li>
          </ul>
          <ul>
            <li class="font-14">
              {{ subject.topics ? subject.topics.length - unfinished : 0 }}
            </li>
            <li class="font-14">已答题数</li>
          </ul>
          <ul>
            <li class="font-14">
              {{ subject.topics ? subject.topics.length : 0 }}
            </li>
            <li class="font-14">全部题目</li>
          </ul>
        </div>
        <div v-if="exitTitle === '退出答题'">
          <div class="exit-btn" @click="exit">退出答题</div>
          <div class="continue-btn" @click="exitVisible = false">继续答题</div>
        </div>
        <div v-else>
          <div class="exit-btn" @click="exitVisible = false">取消提交</div>
          <div class="continue-btn" @click="onSubmitTestPaperConfirm">
            提交试卷
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getTestInfo,
  submitTest,
  addTopicCollect,
  testAnalysis,
  getRecordInfo,
  addTestRecord,
  getTestRecord,
  topicCollectDelete,
  topicCollectInfo,
} from "@/api/course";
import { getSystemErrorMap } from "util";
var _this;
var intt;
export default {
  data() {
    return {
      show: true,
      count: 20,
      title: "",
      isAnalysis: false,
      useTime: "00:00:00", // 用时
      nextQuestionValue: "下一题",
      showNextQuestion: true, // 是否显示提交按钮
      practice: {}, // 上一个界面传送过来的数据
      subjectId: "",
      subject: {}, // 请求得到的题目
      subjectInfo: {}, // 一道题目的信息
      subjectIndex: 0, // 做到第几道题目的索引
      last: 0, // 最后一道题的序号
      textareaValue: "",
      oldSelectIndex: "", // 上一次的选择
      chooseList: [], //做完题目的ID集合
      showAnalysis: false,
      analysisInfo: {},
      showCollection: false,
      viewResolution: false,
      dialogVisible: false,
      question: [], // 当前题目页的20道题
      questionNumber: [], // 当前题目页的20道题序号
      questionNumberId: 0, // 当前展示的题目序号
      questionNumber2: [], // 答完题的题目列表序号
      minute1: true,
      result2: {
        appTestVo: {
          name: "",
        },
      },
      result: "",
      correctQuantity: "", // 题目正确数量
      correctRate: "", //正确率
      isNext: false,
      exitVisible: false, //退出答题的弹框
      unfinished: 0, //未完成的题目
      exitTitle: "退出答题",
      collectId: 0, //收藏id
      analysisList: [], //解析后的答案
      rightTopic: 0, //做对数量
      mistakeTopic: 0, //做错数量
      pattern: 0,
      multiple: [], //多选的答案
      confirmButton: true,
      multipleChoiceIndex: [],
      pagesu: 1,
      topDisabled: true, //上一题按钮的禁用状态
      okExit: false,
      routerPath: "/",
      isLast: true
    };
  },
  beforeRouteLeave: function (to, from, next) {
    console.log(this.dialogVisible);
    if (this.dialogVisible || this.okExit) {
      next();
    } else {
      var number = 0; // 未做的题目数量
      _this.result = "";
      _this.subject.topics.forEach((item, i) => {
        if (item.type === 1) {
          var t = false;
          item.topicOptions.forEach((item2, j) => {
            if (item2.isClick) {
              _this.result = _this.result + item.id + ":" + item2.opt + ",";
              t = true;
            }
            // 判断选择题有无被选中
            if (item.topicOptions.length === j + 1 && !t) {
              _this.result = _this.result + item.id + ": ,";
              number++;
            }
          });
        } else if (item.type === 2) {
          // 得到多选题选项
          var result = item.id + ":";
          item.topicOptions.forEach((item2, j) => {
            if (item2.isClick) {
              result = result + item2.opt + "、";
            }
          });
          if (result.lastIndexOf("、") === result.length - 1) {
            // 去掉最后的一个顿号
            result = result.substring(0, result.lastIndexOf("、"));
          }
          // 如果未选择，则在逗号前留一个空格
          if (result.substring(result.length - 1, result.length) == ":") {
            _this.result = _this.result + result + " ,";
            number++;
          } else {
            _this.result = _this.result + result + ",";
          }
        } else {
          // 得到简答题选项
          if (item.briefAnswer === "") {
            item.briefAnswer = " ";
            number++;
          }
          _this.result = _this.result + item.id + ":" + item.briefAnswer + ",";
        }
      });
      var str = "";
      this.exitTitle = "退出答题";
      if (number > 0) {
        this.exitVisible = true;
        this.unfinished = number; //未完成的题目
        this.routerPath = to.path;

        // str = "还有 " + number + " 题未做，确认提交试卷？";
      } else {
        str = "确认退出答题？";
      }
      if (!this.exitVisible) {
        _this
          .$confirm(str)
          .then((_) => {
            _this.onSubmitTestPaperConfirm();
            done();
          })
          .catch((_) => {
            next();
          });
      }
    }
  },
  mounted() {
    _this = this;
    this.pattern = _this.$route.query.type;
    // 1是练习模式
    if (this.pattern == "1") {
      _this.showCollection = true;
      _this.viewResolution = true;
      _this.subjectId = _this.$route.query.id;
      _this.getSubject();

    } else if (this.pattern == '3') {
      // 3继续上次答题
      _this.showCollection = true;
      _this.viewResolution = true;
      _this.subjectId = _this.$route.query.id;
      this.getLastPractice()
    } else {
      _this.subjectId = _this.$route.query.id;
      _this.getSubject();
    }


    _this.start();
  },
  methods: {
    // 获取上一次的练习
    getLastPractice() {
      this.loading = true
      getTestRecord(this.$route.query.id).then(res => {
        if (res && res.code == 200) {
          console.log(res);
          this.dealWithProblems(res)
          // this.getRightAndWrong()  // 遍历题目的对错
          this.loading = false
        }
      }).catch(() => {
        this.loading = false
      })
    },
    // 遍历题目的对错
    getRightAndWrong() {
      this.mistakeTopic = 0;
      this.rightTopic = 0;
      this.subject.topics.forEach((item1, index1) => {
        if (item1.testResultVo) {
          if (item1.testResultVo.correct === 1) {
            this.rightTopic++;
          } else {
            this.mistakeTopic++;
          }
          // 得到选中的答案是否是正确的
          if (item1.type == 1 || item1.type === 2) {
            item1.topicOptions.forEach((item2, index2) => {
              if (item2.opt === item1.testResultVo.answer) {
                item1.topicOptions[index2] = Object.assign(item2, { isFalse: true })
              } else if (item2.opt === item1.testResultVo.yourAnswer) {
                item1.topicOptions[index2] = Object.assign(item2, { isFalse: false })
              }
            })
            this.subject.topics[index1] = item1
          }
        }
      })
    },
    dealWithProblems(res) {
      var subject = res.data
      for (var i = 0; i < subject.topics.length; i++) {
        if (subject.topics[i].topicOptions != null) {
          // 单选题 多选题
          for (var j = 0; j < subject.topics[i].topicOptions.length; j++) {
            // 加上isClick到数组对象里面
            if (this.$route.query.type == 3) {
              // 重复上次的练习需要额外处理
              if (subject.topics[i].topicOptions[j].opt == subject.topics[i].beforeAnswer) {
                subject.topics[i].topicOptions[j] = Object.assign(subject.topics[i].topicOptions[j], { isClick: true });
                subject.topics[i]['answerBool'] = true
                _this.collectId = subject.topics[i].id
                if (_this.chooseList.indexOf(subject.topics[i].id) == -1) {
                  _this.chooseList.push(subject.topics[i].id);
                }
                subject.topics[i]['analysisList'] = { yourAnswer: subject.topics[i]['beforeAnswer'], answer: subject.topics[i]['testResultVo'].answer }
              } else if (!subject.topics[i].beforeAnswer && this.isLast) {
                // 只执行一次
                subject.topics[i].topicOptions[j] = Object.assign(subject.topics[i].topicOptions[j], { isClick: false });
                _this.isLast = false;
                // this.$refs.swipe.swipeTo(i);
              } else {
                subject.topics[i].topicOptions[j] = Object.assign(subject.topics[i].topicOptions[j], { isClick: false });
                _this.questionNumber.push({ isClick: false })
              }
            }
            // 如果是考试模式，添加字段，用来判断是否选过
          }
        } else {
          // 简答题
          // 如果是考试模式，添加字段，用来判断是否选过
          if (this.examinationMode == "true") {
            if (subject.topics[i].beforeAnswer != " " && subject.topics[i].beforeAnswer) {
              subject.topics[i] = Object.assign(subject.topics[i], { isSelect: true });
            } else {
              subject.topics[i] = Object.assign(subject.topics[i], { isSelect: false });
            }
          }
        }
      }
      _this.subject = subject;

      this.currentChange(1)
      this.setNavigationBarTitle(this.subject.name);
      this.goToNewQuestion(this.questionNumber[0], 0)
    },

    /**
    * 简答题答案发生改变时
    */
    input(event) {
      if (!_this.isNext) {
        _this.subject.topics[_this.subjectIndex].briefAnswer = event;
      }
    },
    currentChange(currentPage) {
      _this.question = [];
      _this.questionNumber = [];
      var endPage = currentPage * 20;
      var startPage = endPage - 20;
      for (var i = 0; i < _this.subject.topics.length; i++) {
        if (i >= startPage && i < endPage) {
          _this.question.push(_this.subject.topics[i]);
          var click = false;
          if (
            _this.subject.topics[i].type === 1 ||
            _this.subject.topics[i].type === 2
          ) {
            _this.subject.topics[i].topicOptions.forEach((item, index) => {
              if (item.isClick) {
                click = true;
              }
            });
          } else {
            // 简答题另外处理
            if (_this.subject.topics[i].briefAnswer != "") {
              click = true;
            }
          }
          _this.questionNumber.push({
            id: _this.subject.topics[i].id,
            num: i,
            isClick: click,
          });
        }
      }
    },
    /**
    * 获得题目
    */
    getSubject() {
      _this.subjectId = parseInt(_this.subjectId);
      getTestInfo(_this.subjectId).then((response) => {
        var chlick = {
          isClick: false,
          collection: false,
        };
        var number = {
          number: 0,
        };
        var briefAnswer = {
          briefAnswer: "",
          number: 0,
        };
        var data = response.data;
        this.collectId = data.topics[0].id;
        for (var i = 0; i < data.topics.length; i++) {
          if (data.topics[i].topicOptions != null) {
            number.number = i + 1;
            data.topics[i] = Object.assign(data.topics[i], number);
            for (var j = 0; j < data.topics[i].topicOptions.length; j++) {
              data.topics[i].topicOptions[j].value = data.topics[
                i
              ].topicOptions[j].value.replace(/\n/g, "<br>");
              _this.last = data.topics.id;
              // 加上isClick到数组对象里面
              data.topics[i].topicOptions[j] = Object.assign(
                data.topics[i].topicOptions[j],
                chlick
              );
            }
          } else {
            briefAnswer.number = i + 1;
            data.topics[i] = Object.assign(data.topics[i], briefAnswer);
          }
        }
        _this.subject = data;
        this.analysisList = Object.assign({
          yourAnswer: "",
          answer: "",
        });
        _this.subjectInfo = Object.assign(
          _this.subject.topics[_this.subjectIndex],
          { answerBool: false },
          { analysisList: this.analysisList }
        );
        _this.subject.topics.forEach((item, index) => {
          if (index < 20) {
            _this.questionNumber.push({
              id: _this.subject.topics[index].id,
              num: index,
              isClick: false,
              collection: false,
            });
          }
        });
        _this.questionNumberId = _this.questionNumber[0].id;
        _this.setNavigationBarTitle(_this.subject.name);
      });
    },
    /**
    * 选择一个单选题选项
    */
    chooseAnswer(index, id, opt) {
      //存储做完的题目集合
      this.collectId = id;
      if (this.chooseList.indexOf(id) == -1) {
        this.chooseList.push(id);
      }
      var a = _this.subjectInfo.topicOptions[index];
      // 如果是从错误解析进入，将不会执行点击事件
      if (!_this.subjectInfo.answerBool) {
        if (_this.subjectId !== -1 && _this.subjectId !== -2) {
          var arr = _this.subjectInfo.topicOptions;
          this.confirmButton = false;
          if (this.pattern == "1" || this.pattern == '3') {
            _this.subjectInfo.answerBool = true;
          }

          if (_this.subjectInfo.type === 1) {
            // 如果已选中，则取消选中，否则相反
            if (arr[index].isClick == true) {
              arr[index].isClick = false;
            } else {
              arr[index].isClick = true;
              arr.forEach((item, index2) => {
                if (index != index2) {
                  arr[index2].isClick = false;
                }
              });
            }
          } else {
            // 多选题
            if (arr[index].isClick == true) {
              arr[index].isClick = false;
            } else {
              arr[index].isClick = true;
            }
          }
          _this.questionNumber.forEach((item, index2) => {
            if (item.id === id) {
              _this.questionNumber[index2].isClick =
                _this.subjectInfo.topicOptions[index].isClick;
            }
          });
          _this.subjectInfo.topicOptions = arr;
          _this.oldSelectIndex = index;
          if (_this.$route.query.type == 1 || _this.$route.query.type == 3) {
            //只有练习模式才会执行
            this.viewAnalysis(true);
          }
        }
      }
    },

    /**
    * 多选
    */
    chooseAnswerChoice(id, multiple, bool) {
      //参数说明，题目id，我的答案multiple
      if (this.subjectInfo.type == 2) {
        var myAnswer = "";
        if (multiple.length == 0) {
          this.$message({
            message: "您还未选择答案~",
            type: "warning",
          });
          return;
        }
        multiple.forEach((e, i) => {
          if (i == multiple.length) {
            myAnswer = myAnswer + e;
          } else {
            myAnswer = myAnswer + e + "、";
          }
        });
      }
      if (this.subjectInfo.type == 3) {
        if (multiple == "") {
          this.$message({
            message: "您还未输入答案哦~",
            type: "warning",
          });
          return;
        }
        myAnswer = multiple;
      }
      if (myAnswer.lastIndexOf("、") === myAnswer.length - 1) {
        // 去掉最后的一个顿号
        myAnswer = myAnswer.substring(0, myAnswer.lastIndexOf("、"));
      }
      if (!multiple) {
        return;
      }
      _this.questionNumber.forEach((item, index2) => {
        if (item.id === id) {
          _this.questionNumber[index2].isClick = true;
          // _this.subjectInfo.topicOptions[_this.subjectIndex].isClick;
        }
      });
      if (_this.$route.query.type == 1) {
        //只有练习模式才会执行
        testAnalysis({
          answer: myAnswer,
          // testId: _this.subjectId,
          topicId: id,
        }).then((res) => {
          if (res != undefined) {
            this.confirmButton = false;
            this.analysisInfo = res.data;
            if (!this.analysisInfo.answer) {
              this.analysisInfo.answer.replace("&rarr;", "→");
            }
            if (!this.analysisInfo.analysis) {
              this.analysisInfo.analysis.replace("&rarr;", "→");
            }
            this.analysisList = Object.assign({
              yourAnswer: this.analysisInfo.yourAnswer,
              answer: this.analysisInfo.answer,
            });
            _this.subjectInfo.answerBool = true;
            this.subjectInfo = Object.assign(this.subjectInfo, {
              analysisList: this.analysisList,
            });
            if (bool) {
              let str = _this.subjectInfo.analysisList.answer.split(',').join('')
              let str1 = _this.subjectInfo.analysisList.yourAnswer.split('、').join('')
              console.log(str);
              console.log(str1);

              if (
                this.analysisInfo.correct == 1
              ) {
                this.rightTopic++; //题目做对的数量
              } else {
                this.mistakeTopic++; //题目做错的数量
              }
            }
            this.showAnalysis = true;
          }
        });
      }
    },

    /**
    * 解析
    */
    viewAnalysis(bool) {
      var arr = _this.subjectInfo.topicOptions;
      var info = _this.subjectInfo;
      var answer = "1";
      if (info.type == 1 || info.type == 2) {
        // 得到当前题目被选中的答案
        var opts = "";
        info.topicOptions.forEach((item, index) => {
          if (item.isClick) {
            if (opts != "") {
              opts = opts + "、" + item.opt;
            } else {
              opts += item.opt;
            }
          }
        });
        answer = opts;
      } else {
        // 得到当前填空题的答案
        answer = info.briefAnswer;
      }
      if (answer == "") {
        return;
      }
      testAnalysis({
        answer: answer,
        // testId: _this.subjectId,
        topicId: info.id,
      }).then((res) => {
        if (res != undefined) {
          this.analysisInfo = res.data;
          if (this.analysisInfo.answer) {
            this.analysisInfo.answer.replace("&rarr;", "→");
          }
          if (this.analysisInfo.analysis) {
            this.analysisInfo.analysis.replace("&rarr;", "→");
          }

          this.analysisList = Object.assign({
            yourAnswer: this.analysisInfo.yourAnswer,
            answer: this.analysisInfo.answer,
          });
          this.subjectInfo = Object.assign(this.subjectInfo, {
            analysisList: this.analysisList,
          });
          if (bool) {
            if (
              this.subjectInfo.analysisList.yourAnswer ==
              this.subjectInfo.analysisList.answer
            ) {
              this.rightTopic++; //题目做对的数量
            } else {
              this.mistakeTopic++; //题目做错的数量
            }
          }
          this.showAnalysis = true;
        }
      });
    },
    /**
    * 点击了下一题
    */
    nextQuestion(event) {
      this.multiple = []
      //判断上一题的按钮禁用状态
      if (_this.subjectIndex + 1 != 0) {
        this.topDisabled = false;
      } else {
        this.topDisabled = true;
      }
      if (event === "下一题") {
        _this.isNext = true;
        _this.textareaValue = "";
        _this.isNext = false;
        _this.subjectIndex = Number(_this.subjectIndex) + 1;
        var num = _this.subjectIndex / 20;
        if (num % 1 === 0) {
          this.pagesu++;
          this.currentChange(this.pagesu);
        }
        if (!_this.subject.topics[_this.subjectIndex].answerBool) {
          this.analysisList = Object.assign({
            yourAnswer: "",
            answer: "",
          });
          _this.subjectInfo = Object.assign(
            _this.subject.topics[_this.subjectIndex],
            { answerBool: false },
            { analysisList: this.analysisList }
          );
        } else {
          var answerBoolNew =
            _this.subject.topics[_this.subjectIndex].answerBool;
          var analysisListNew =
            _this.subject.topics[_this.subjectIndex].analysisList;
          _this.subjectInfo = Object.assign(
            _this.subject.topics[_this.subjectIndex],
            { answerBool: answerBoolNew },
            { analysisList: analysisListNew }
          );
        }
        this.hideAnalysis();
        _this.questionNumberId = _this.subjectInfo.id;

        if (_this.subjectIndex < _this.subject.topics.length - 1) {
          _this.nextQuestionValue = "下一题";
        } else {
          var typeid = JSON.parse(_this.subjectId).typeId;
          if (typeid == -1 || typeid == -2) {
            _this.showNextQuestion = false; // 是否显示提交按钮
          }
          _this.nextQuestionValue = "提交";
        }
        if (
          (this.pattern == "1" || this.pattern == '3') &&
          _this.subjectInfo.type != 3 &&
          this.subjectInfo.analysisList.yourAnswer != ""
        ) {
          //判断此题有没有答题
          this.viewAnalysis(false); //解析
        }
        if (
          _this.subjectInfo.type === 3 &&
          this.subjectInfo.analysisList.yourAnswer != ""
        ) {
          //判断此题有没有答题
          _this.textareaValue = _this.subjectInfo.briefAnswer;
          this.chooseAnswerChoice(
            this.subjectInfo.id,
            _this.textareaValue,
            false
          ); //解析
        }
      } else {
        // 提交
        var number = 0; // 未做的题目数量
        _this.result = "";
        _this.subject.topics.forEach((item, i) => {
          if (item.type === 1) {
            var t = false;
            item.topicOptions.forEach((item2, j) => {
              if (item2.isClick) {
                _this.result = _this.result + item.id + ":" + item2.opt + ",";
                t = true;
              }
              // 判断选择题有无被选中
              if (item.topicOptions.length === j + 1 && !t) {
                _this.result = _this.result + item.id + ": ,";
                number++;
              }
            });
          } else if (item.type === 2) {
            // 得到多选题选项
            var result = item.id + ":";
            item.topicOptions.forEach((item2, j) => {
              if (item2.isClick) {
                result = result + item2.opt + "、";
              }
            });
            if (result.lastIndexOf("、") === result.length - 1) {
              // 去掉最后的一个顿号
              result = result.substring(0, result.lastIndexOf("、"));
            }
            // 如果未选择，则在逗号前留一个空格
            if (result.substring(result.length - 1, result.length) == ":") {
              _this.result = _this.result + result + " ,";
              number++;
            } else {
              _this.result = _this.result + result + ",";
            }
          } else {
            // 得到简答题选项
            if (item.briefAnswer === "") {
              item.briefAnswer = " ";
              number++;
            }
            _this.result =
              _this.result + item.id + ":" + item.briefAnswer + ",";
          }
        });
        var str = "";
        this.exitTitle = "提交试卷";
        if (number > 0) {
          this.exitVisible = true;
          this.unfinished = number; //未完成的题目

          // str = "还有 " + number + " 题未做，确认提交试卷？";
        } else {
          str = "确认提交试卷？";
        }
        if (!this.exitVisible) {
          _this
            .$confirm(str)
            .then((_) => {
              _this.onSubmitTestPaperConfirm();
              done();
            })
            .catch((_) => { });
        }
      }
    },
    /**
    * 点击了上一题
    */
    lastQuestion: function () {
      this.hideAnalysis();
      _this.showNextQuestion = true; // 是否显示提交按钮
      _this.nextQuestionValue = "下一题";
      if (_this.subjectIndex != 0) {
        _this.subjectIndex = _this.subjectIndex - 1;

        //触发题目分页变动
        var num = (_this.subjectIndex + 1) / 20;
        if (num % 1 === 0) {
          this.pagesu--;
          this.currentChange(this.pagesu);
        }

        if (!_this.subject.topics[_this.subjectIndex].answerBool) {
          this.analysisList = Object.assign({
            yourAnswer: "",
            answer: "",
          });
          _this.subjectInfo = Object.assign(
            _this.subject.topics[_this.subjectIndex],
            { answerBool: false },
            { analysisList: this.analysisList }
          );
        } else {
          var answerBoolNew =
            _this.subject.topics[_this.subjectIndex].answerBool;
          var analysisListNew =
            _this.subject.topics[_this.subjectIndex].analysisList;
          _this.subjectInfo = Object.assign(
            _this.subject.topics[_this.subjectIndex],
            { answerBool: answerBoolNew },
            { analysisList: analysisListNew }
          );
        }

        _this.questionNumberId = _this.subjectInfo.id;
        _this.textareaValue =
          _this.subject.topics[_this.subjectIndex].briefAnswer;
      } else {
        this.topDisabled = true;
      }
      if (
        (this.pattern == "1" || this.pattern == '3') &&
        _this.subjectInfo.type != 3 &&
        this.subjectInfo.analysisList.yourAnswer != ""
      ) {
        this.viewAnalysis(false);
      }
      if (
        _this.subjectInfo.type === 3 &&
        this.subjectInfo.analysisList.yourAnswer != ""
      ) {
        _this.textareaValue = _this.subjectInfo.briefAnswer;
        this.chooseAnswerChoice(
          this.subjectInfo.id,
          _this.textareaValue,
          false
        );
      }
    },
    /**
    * 点击收藏
    */
    collection() {
      var answer, correct, topicId;
      if (
        _this.analysisInfo.topicId == undefined ||
        _this.analysisInfo.topicId == ""
      ) {
        _this.$message("题目解析后才能收藏");
        return;
      }
      answer = _this.analysisInfo.yourAnswer;
      correct = _this.analysisInfo.correct;
      topicId = _this.analysisInfo.topicId;

      addTopicCollect({
        yourAnswer: answer,
        correct: correct,
        topicId: topicId,
      }).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          var isCollection = this.subject.topics[this.subjectIndex].collection;
          if (isCollection) {
            this.subject.topics[this.subjectIndex].collection = false;
          } else this.subject.topics[this.subjectIndex].collection = true;
        }
      });
    },
    hideAnalysis() {
      // 隐藏掉解析
      this.analysisInfo = {};
      this.showAnalysis = false;
    },
    /**
    * 获得答案详情
    */
    getAnswerDetail(test) {
      test.appTestVo.topics.forEach(function (item, index) {
        // 记录多选题未选、正确、错误答案
        if (item.type === 3) {
          item.testResultVo.answer = item.testResultVo.answer.replace(
            /\n/g,
            "<br>"
          );
        }
        if (item.type === 2) {
          item.topicOptions.forEach(function (item1, index1) {
            var resu = {
              // 0：未选择，1：答案正确，2：答案错误
              result: 0,
            };
            item.topicOptions[index1] = Object.assign(item1, resu);
            var youAnswer = item.testResultVo.yourAnswer.split("、");
            youAnswer.forEach(function (item2, index2) {
              if (item1.opt == item2) {
                item.topicOptions[index1].result = 2;
              }
            });
            var answer = item.testResultVo.answer.split(",");

            answer.forEach(function (item2, index2) {
              if (item1.opt == item2) {
                item.topicOptions[index1].result = 1;
              }
            });
          });
          test.appTestVo.topics[index] = item;
        }
      });
      _this.subject = test.appTestVo;
      if (test.index !== undefined) {
        _this.subjectIndex = test.index;
        test.appTestVo.topics.forEach((item) => {
          item.title = item.title.replace(/\n/g, "<br>");
          if (item.topicOptions) {
            item.topicOptions.forEach((item1) => {
              item1.value = item1.value.replace(/\n/g, "<br>");
            });
          }
        });
        _this.subjectInfo = test.appTestVo.topics[_this.subjectIndex];
        if (test.index == _this.subject.topics.length - 1) {
          _this.showNextQuestion = false;
        }
      } else {
        test.appTestVo.topics.forEach((item) => {
          item.title = item.title.replace(/\n/g, "<br>");
          if (item.topicOptions) {
            item.topicOptions.forEach((item1) => {
              item1.value = item1.value.replace(/\n/g, "<br>");
            });
          }
        });
        _this.subjectInfo = test.appTestVo.topics[_this.subjectIndex];
      }
    },
    /**
    * 点击提交试卷后的确认按钮
    */
    onSubmitTestPaperConfirm() {
      submitTest({
        answer: this.result,
        testId: this.subjectId,
        useTime: this.getSecond(),
      }).then((response) => {
        console.log('this.result', this.result);
        console.log('response', response);

        _this.testRecordId = response.data.testRecordId;
        _this.getTestResult(response.data.testRecordId);
      });
    },
    /**
    * 点击查看全部解析
    */
    viewAllAnalysis() {
      _this.$router.push({
        path: "analysis",
        query: { id: _this.testRecordId },
      });
    },
    /**
    * 获取测试的结果
    */
    getTestResult(id) {
      getRecordInfo({ id: id }).then((res) => {
        _this.dialogVisible = true;
        _this.result2 = res.data;
        _this.result2.appTestVo.topics.forEach((item, index) => {
          if (index < 36) {
            _this.questionNumber2.push({
              num: index,
              correct: item.testResultVo.correct,
            });
          }
        });
        _this.result2 = res.data;
        var i = 0;
        var number = {
          number: "",
        };
        res.data.appTestVo.topics.forEach(function (item, index) {
          number.number = index + 1;
          res.data.appTestVo.topics[index] = Object.assign(
            res.data.appTestVo.topics[index],
            number
          );
          if (item.testResultVo.correct === 1) i++;
        });
        // 所用时间不够一分钟用秒数
        if (res.data.useTime > 60) {
          res.data.useTime = (res.data.useTime / 60).toFixed(2);
          _this.minute1 = true;
        } else {
          _this.minute1 = false;
        }
        _this.result2 = res.data;
        _this.correctQuantity = i + "/" + res.data.appTestVo.topics.length;
        _this.correctRate = (
          (i / res.data.appTestVo.topics.length) *
          100
        ).toFixed(0);
        if (_this.correctRate > 60) {
          _this.correctRateColor = "#6fddab";
        }
      });
    },
    currentChange2(page) {
      _this.questionNumber2 = [];
      var endPage = page * 36;
      var startPage = endPage - 36;
      var topi = _this.result2.appTestVo.topics;
      for (var i = 0; i < topi.length; i++) {
        if (i >= startPage && i < endPage) {
          _this.questionNumber2.push({
            num: i,
            correct: topi[i].testResultVo.correct,
          });
        }
      }
    },
    /**
    * 简答题的值
    */
    briefAnswer(event, id) {
      this.subject.topics[this.subjectIndex].briefAnswer = event;
      //存储做完的题目集合
      if (this.chooseList.indexOf(id) == -1 && event != "") {
        this.chooseList.push(id);
      }
    },
    // 关闭显示成绩的对话框
    handleClose(done) {
      // done();
      this.$router.push({
        path: "selectClass",
        query: {
          id: localStorage.getItem("selectClassId"),
          subjectId: localStorage.getItem("subjectId"),
        },
      });
    },
    /**
    * 开始计时
    */
    start() {
      //停止（暂停）
      clearInterval(intt);
      //时间重置
      (_this.hour = 0),
        (_this.minute = 0),
        (_this.second = 0),
        (_this.millisecond = 0);
      intt = setInterval(function () {
        _this.timer();
      }, 50);
    },
    /**
    * 暂停计时
    */
    stop() {
      clearInterval(intt);
    },
    /**
    * 计算做题花费的时间（秒）
    */
    getSecond() {
      var split = this.useTime.split(":");
      var h = parseInt(split[0]);
      var m = parseInt(split[1]);
      var s = parseInt(split[2]);
      var num = 0;
      for (var i = 0; i < h; i++) {
        num += 3600;
      }
      num += m * 60 + s;
      return num;
    },
    timer() {
      _this.millisecond = _this.millisecond + 5;
      if (_this.millisecond >= 100) {
        _this.millisecond = 0;
        _this.second = _this.second + 1;
      }
      if (_this.second >= 60) {
        _this.second = 0;
        _this.minute = _this.minute + 1;
      }

      if (_this.minute >= 60) {
        _this.minute = 0;
        _this.hour = _this.hour + 1;
      }
      var hour = _this.hour < 10 ? "0" + _this.hour : _this.hour;
      var minute = _this.minute < 10 ? "0" + _this.minute : _this.minute;
      var second = _this.second < 10 ? "0" + _this.second : _this.second;
      _this.useTime = hour + ":" + minute + ":" + second;
    },
    /**
    * 设置标题
    */
    setNavigationBarTitle(title) {
      _this.title = title;
    },
    onClickLeft() {
      _this.$router.go(-1);
    },
    /**
    * 判断是否是整数
    */
    isInteger(obj) {
      return parseInt(obj, 10) == obj;
    },
    // 预览题目图片
    previewImg(src) {
      ImagePreview([src]);
    },
    // 点击目录跳转到对应题目
    goToNewQuestion(item, index) {
      console.log(item, index);
      if (index + 1 == this.questionNumber.length) {
        _this.nextQuestionValue = "提交";
      } else {
        _this.nextQuestionValue = "下一题";
      }
      this.hideAnalysis();
      _this.showNextQuestion = true; // 是否显示提交按钮
      _this.subjectIndex = item.num;
      if (!_this.subject.topics[_this.subjectIndex].answerBool) {
        this.analysisList = Object.assign({
          yourAnswer: "",
          answer: "",
        });
        _this.subjectInfo = Object.assign(
          _this.subject.topics[_this.subjectIndex],
          { answerBool: false },
          { analysisList: this.analysisList }
        );
      } else {
        var answerBoolNew = _this.subject.topics[_this.subjectIndex].answerBool;
        var analysisListNew =
          _this.subject.topics[_this.subjectIndex].analysisList;
        _this.subjectInfo = Object.assign(
          _this.subject.topics[_this.subjectIndex],
          { answerBool: answerBoolNew },
          { analysisList: analysisListNew }
        );
      }
      _this.questionNumberId = _this.subjectInfo.id;
      _this.textareaValue =
        _this.subject.topics[_this.subjectIndex].briefAnswer;
      if (
        (this.pattern == "1" || this.pattern == '3') &&
        _this.subjectInfo.type != 3 &&
        this.subjectInfo.analysisList.yourAnswer != ""
      ) {
        this.viewAnalysis(false);
      }
      if (
        _this.subjectInfo.type === 3 &&
        this.subjectInfo.analysisList.yourAnswer != ""
      ) {
        _this.textareaValue = _this.subjectInfo.briefAnswer;
        this.chooseAnswerChoice(
          this.subjectInfo.id,
          _this.textareaValue,
          false
        );
      }
    },

    //多选的答案
    onSelect(index, id, e) {
      if (this.subjectInfo.answerBool) {
        return;
      }
      var arr = _this.subjectInfo.topicOptions;
      if (arr[index].isClick == true) {
        arr[index].isClick = false;
        this.multiple.splice(
          this.multiple.findIndex((i) => i === e),
          1
        );
      } else {
        arr[index].isClick = true;
        this.multiple.push(e);
        this.multipleChoiceIndex.push(index);
      }
    },
    exit() {
      console.log(this.$route.query.userId);
      this.subject.topics.length = this.unfinished;
      this.okExit = true;
      addTestRecord({
        answer: this.result,
        testId: this.subject.id,
        topicId: this.subject.topics[this.subjectIndex].id,
        useTime: this.getSecond(),
      }).then(() => {
        console.log('保存记录');
        this.$router.push({
          path: this.routerPath,
          query: { id: this.$route.query.userId },
        });
      }).catch(() => {
        this.$router.push({
          path: this.routerPath,
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.body {
  width: 1200px;
  height: 100vh;
  margin: 0 auto;

  .body-content {
    margin-left: 68px;

    .row-name {
      font-size: 20px;
    }

    .line {
      height: 5px;
      width: 60px;
      margin: 4px 0 30px 0;
      background-color: #4394ff;
      border-radius: 5px;
    }

    .practicing {
      margin-top: 6px;
      display: -webkit-inline-box;
      font-weight: 400;
      margin-bottom: 50px;

      .practicing-type {
        font-size: 12px;
        color: #fff;
        background-color: #4394ff;
        width: 46px;
        height: 21px;
        border-radius: 11px;
        line-height: 21px;
        text-align: center;
        margin-right: 10px;
      }

      .practicing-content {
        width: 655px;
        font-size: 20px;
        color: #000000;
        margin-right: 80px;

        .practicing-option {
          margin-top: 20px;
          display: flex;
          cursor: pointer;
        }

        .view-analysis {
          font-size: 18px;
          font-weight: 400;
          color: #4394ff;
          margin-top: 50px;
          display: flex;
          align-items: center;
          cursor: pointer;
        }

        .practicing-answer {
          margin-top: 40px;
          display: flex;
          flex-direction: row;
          background-color: #acacac;
          width: 335px;
          height: 80px;
          background: #ffffff;
          box-shadow: 0px 0px 10px rgba(152, 152, 152, 0.16);

          .practicing-answer-div {
            font-weight: 400;
            width: 50%;
            height: 100%;
            display: flex;
            flex-direction: column;
            text-align: center;
            margin-top: 10px;

            .practicing-answer-text {
              font-size: 18px;
              color: #000000;
              line-height: 28px;
              flex: 1;
            }

            .practicing-answer-option {
              font-size: 26px;
              color: #4394ff;
              flex: 6;
            }
          }
        }

        .practicing-analysis {
          font-size: 18px;
          font-weight: 400;
          line-height: 30px;
          color: #000000;
          margin-top: 15px;
        }
      }

      .practicing-time {
        width: 315px;
        height: 368px;

        .practicing-time-clock {
          width: 100%;
          height: 80px;
          background: #ffffff;
          border: 1px solid #c9c9c9;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;

          .practicing-time-clock-text {
            font-size: 30px;
            font-weight: 400;
            color: #4394ff;
            margin-left: 3px;
          }

          .practicing-time-clock-text-fold {
            margin: 35px 0 0 60px;

          }
        }

        .practicing-time-option {
          margin-top: 10px;
          height: 368px;
          background: #ffffff;
          border: 1px solid #c9c9c9;
          border-radius: 5px;
          padding: 15px 20px 0 20px;
          position: relative;

          .option-number {
            font-size: 20px;
            font-weight: 400;
            color: #000000;
            margin-bottom: 15px;
          }

          .option-line {
            height: 1px;
            background-color: #dcdfe6;
          }

          .option-bottom {
            position: absolute;
            bottom: 0;
            width: 265px;

            .option-bottom-page {
              text-align: center;
              margin-top: 30px;
            }

            .option-bottom-submit {
              margin: 20px auto;
              font-size: 18px;
              font-weight: 400;
              color: #4394ff;
              text-align: center;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .option-result-page {
    text-align: center;
    margin-top: 130px;
  }

  .body-button {
    width: 730px;
    height: 100px;

    .body-button-content {
      display: flex;
      flex: 3;

      .body-button-button {
        width: 96px;
      }

      .body-button-center {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: 400;
        line-height: 33px;
        cursor: pointer;

        .body-button-center-text {
          color: #4394ff;
          line-height: 24px;
          margin-left: 5px;
        }

        .body-button-center-img {
          align-items: center;
        }
      }
    }
  }
}

.dialog-options {
  width: 480px;
  height: 95px;
  margin: 0 auto;

  .dialog-option {
    margin-top: 20px;
  }
}

.isSelect {
  background-color: #b4d2ff;
  color: #438eff;
}

.font-white {
  background-color: #b4d2ff;
  color: #438eff;
}

.notSelect {
  background-color: white;
  color: #535353;
}

.option-options {
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;

  .option-option {
    width: 35px;
    height: 35px;
    border: 1px solid #e3e3e3;
    border-radius: 5px;
    text-align: center;
    line-height: 35px;
    margin: 15px 8px 0 8px;
    cursor: pointer;
  }
}

.select {
  color: #4394ff !important;
}

.no-select {
  color: black;
}

.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 640px;
  height: 700px;

  .circular1 {
    position: absolute;
    left: 126px;
    top: 62px;
  }

  .circular2 {
    position: absolute;
    top: 162px;
    left: 135px;
  }

  .circular3 {
    position: absolute;
    top: 67px;
    right: 190px;
  }

  .circular4 {
    position: absolute;
    right: 150px;
  }

  .circular5 {
    position: absolute;
    top: 218px;
    right: 125px;
  }

  .center-img {
    width: 190px;
    height: 190px;
    color: #f2f2f2;
    background-image: url("../../assets/achievement.png");
    background-size: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .center-img-content {
      height: 45px;
      font-size: 32px;
      font-weight: bold;
      color: #ffffff;
    }

    .center-img-text {
      font-size: 13px;
      font-weight: bold;
      color: #fff;
      text-align: center;
    }
  }
}

.dialog-center {
  width: 470px;
  height: 145px;
  margin: 0 auto;

  .circle-title {
    text-align: center;
    margin-top: 20px;
    height: 28px;
    font-size: 20px;
    font-weight: 400;
    color: #acacac;
  }

  .circle-statistics {
    margin-top: 32px;
    display: flex;
    flex: 5;

    .circle-statistics-left {
      flex: 2;
      text-align: center;
      margin-top: 10px;
    }

    .circle-statistics-center {
      width: 1px;
      height: 70px;
      background-color: #ebebeb;
    }

    .circle-statistics-right {
      flex: 2;
      text-align: center;
      margin-top: 10px;
    }
  }
}

.circle-statistics-title {
  font-size: 18px;
  font-weight: 400;
  color: #000000;
}

.circle-statistics-text {
  font-size: 14px;
  font-weight: 400;
  color: #acacac;
  margin-top: 10px;
}

.divider {
  width: 471px;
  height: 1px;
  margin-top: 20px;
  background-color: #ebebeb;
}

.view-all-analysis {
  width: 84px;
  height: 20px;
  margin: 60px auto 40px auto;
  font-size: 14px;
  font-weight: 400;
  color: #4394ff;
  cursor: pointer;
}

.error {
  color: #ff6060;
  background-color: #ffcece;
}

.el-dialogVisible {
  display: flex;
  flex-direction: column;
  align-items: center;

  .exit-title {
    font-size: 24px;
  }

  .exit-content {
    font-size: 16px;
    margin-top: 15px;
    margin-bottom: 30px;
  }

  .exit-list {
    margin-top: 30px;
    margin-bottom: 40px;

    ul {
      display: inline-block;

      li {
        text-align: center;
      }
    }
  }

  .color-red {
    color: #ff6060;
  }

  .continue-btn {
    margin-left: 70px;
    width: 120px;
    height: 37px;
    background: linear-gradient(270deg, #657bfe 0%, #38befe 100%);
    opacity: 1;
    border-radius: 50px;
    font-size: 16px;
    font-weight: 400;
    line-height: 37px;
    color: #ffffff;
    text-align: center;
    display: inline-block;
  }

  .exit-btn {
    margin-left: 50px;
    width: 120px;
    height: 37px;
    background: #fff;
    opacity: 1;
    border-radius: 50px;
    font-size: 16px;
    font-weight: 400;
    line-height: 37px;
    color: #000;
    text-align: center;
    display: inline-block;
    border: 1px solid #e3e3e3;
  }
}

ul li {
  list-style: none;
}

.font-14 {
  font-size: 18px;
  margin-top: 5px;
}

.mistakeTopic {
  font-size: 24px;
  color: #ff6060;
  margin-right: 65px;
  margin-left: 5px;
}

.rightTopic {
  font-size: 24px;
  margin-right: 20px;
  color: #438eff;
  margin-left: 5px;
}

.judge {
  margin-top: 10px;
}

.analytical-title {
  padding-left: 15px;
  border-left: 3px solid #4394ff;
}

.confirm-btn {
  width: 246px;
  height: 37px;
  background: linear-gradient(270deg, #657bfe 0%, #38befe 100%);
  opacity: 1;
  border-radius: 50px;
  font-size: 16px;
  font-weight: 400;
  line-height: 37px;
  color: #ffffff;
  text-align: center;
  margin-top: 70px;
  margin-left: 120px;
}
</style>